// Navbar Vertical

.navbar-vertical {
  height: 100vh;
  margin-left: -17rem;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 250px;
  padding: 0rem;
  background-color: $dark;
  border-right: 1px solid $dark;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  .slimScrollDiv {
      height: 100% !important;
      overflow-y: auto;
  }
  .nav-scroller {
      height: 100% !important;
      overflow-y: auto;
  }
  .navbar-brand {
      padding: 1rem 1.5rem 1.5rem;
      margin-right: 0px;
      display: block;
      img {
          height: 1.875rem;
      }
  }
  .navbar-heading {
      color: $gray-700;
      padding: 0.6rem 2rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 0.125rem;
      font-weight: 600;
  }
  .navbar-nav {
      .nav-item {
          .nav-link {
              padding: 0.5rem 1.5rem;
              display: flex;
              align-items: center;
              color: $gray-400;
              font-weight: 500;
              line-height: 1.8;
              -webkit-transition: all 0.5s;
              transition: all 0.5s;
              &:hover {
                  color: $gray-200;
              }

              &:hover .nav-icon {
                  color: $gray-200;
                  opacity: 1;
              }

              &.active {
                  color: $gray-200;
              }

              &.active .nav-icon {
                  color: $gray-200;
                  opacity: 1;
              }
          }
          .nav-link[data-bs-toggle="collapse"] {
              position: relative;

              &:after {
                  display: block;
                  content: "\e92e";
                  font-family: Feather;
                  margin-left: auto;
                  transition: 0.5s ease;
                  position: absolute;
                  right: 1.5rem;
              }
          }
          [aria-expanded="true"].nav-link[data-bs-toggle="collapse"]:after {
              transform: rotate(180deg);
              -webkit-transition: all 0.3s ease-in;
              -o-transition: all 0.3s ease-in;
              transition: all 0.3s ease-in;
          }
      }
      .nav-icon {
          opacity: 0.6;
          font-size: 1.1rem;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
      }
      .nav-divider {
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid rgba($gray-800, 0.5);
      }
      .nav {
          .nav-item {
              .nav-link {
                  padding: 0.25rem 3rem;

                  &:hover {
                      color: $white;
                  }
              }

              .nav {
                  .nav-item {
                      .nav-link {
                          padding: 0.25rem 3.5rem;

                          &:hover {
                              color: $white;
                          }
                      }

                      .nav {
                          .nav-item {
                              .nav-link {
                                  padding: 0.25rem 4.2rem;

                                  &:hover {
                                      color: $white;
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}
