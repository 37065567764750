/* Theme: Geeks */

// Inter Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Font Awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");

// Material Icons
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Feathers CSS for Before Icon
// @import url("../fonts/feather/feather.css");

// // Bootstrap functions
@import "/node_modules/bootstrap/scss/functions";

// // User Variables
@import "/src/assets/scss/user-variables";

// // theme variables
@import "/src/assets/scss/theme/variables";

// //utilities
@import "/src/assets/scss/theme/utilities";

// // Bootstrap
@import "~bootstrap/scss/bootstrap";

// // Theme
@import "/src/assets/scss/theme/theme";

// // User
@import "/src/assets/scss/user";

@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap4.css';
@import "../node_modules/@syncfusion/ej2-angular-inputs/styles/bootstrap4.css";

// // JSON Feature Accordion
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';


.unsupported.offline {
	color: red !important;
	text-decoration: line-through;
}

.offline {
    color: orange !important;
    // font-weight: bold;
}

.online {
    color: green !important;
    // font-weight: bold;
}


@import '~@angular/cdk/overlay-prebuilt.css';
